@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #222129;
  --accent: #F17F41;

  --input-height: 50px;
  --input-margin: 15px 0;
  --input-padding: 0 15px;
  --input-border: 3px solid var(--accent);
  --input-border-radius: 10px;

  --input-font-weight: bold;
  --input-font-size: 18px;
  --input-text-transform: uppercase;

  /**
   * Bootstrap Overrides
   */
   --bs-body-bg: var(--primary);
   --bs-body-color: white;
}

::backdrop,
dialog::backdrop {
  background: rgba(0,0,0,1);
}

html,
body {
  padding: 0;
  margin: 0;
  background: var(--primary);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  box-sizing: border-box;
}


/**
 * iOS Popup override
 */

 .pwa-install-popup-ios .pwa-install-popup-ios-content .left img.appIcon {
  height: auto !important;
  padding: 5px;
  border-radius: 20px;
 }

 .pwa-install-popup-ios {
  animation: none !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
 }
